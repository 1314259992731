<template src="./language.html"></template>

<script>
import Http from '@/shared/http-config'
import CmsStore from '../CmsStore'
import { mapFields } from 'vuex-map-fields';
import RegisterStoreModule from '@/shared/registerStoreModule'
import DisplayWebsite from '@/shared/DisplayWebsite'

export default {
  name: 'Language',
  components: {DisplayWebsite},
  mixins: [RegisterStoreModule],
  created: function(){
    this.registerStoreModule('cms', CmsStore);
    this.start();
  },
  watch: {
    $route(to, from) {
      this.start();
    }
  },
  data: function () {
    return {
      user: null,
      preview_url: '',
    }
  },
  computed: {
    ...mapFields('cms', [
      'template',
      'templates',
      'headers',
      'language',
      'languages',
      'modules',
      'forms',
    ]),
  },
  methods: {
    start: function() {
      let self = this;

      self.user = self.$store.getters.getUser;
      if (self.user.user) {
        self.preview_url = 'https://www.fahrschul-system.de/?changeDomain=' + self.user.user.url + '&template=' + self.$route.params.template;
      }

      Http.request('GET', '/website/header/' + self.$route.params.template).then((headers) => {
        self.headers = headers.data;
      });

      Http.request('GET', '/website/modules/' + self.$route.params.template).then((modules) => {
        self.modules = modules.data;
      });

      Http.request('GET', '/website/language/' + self.$route.params.template).then((languages) => {
        self.languages = languages.data;
        if (self.$route.params.language > 0) {
          self.language = self.languages.filter(item => item.id === self.$route.params.language);
        }
        else if (self.languages.length > 0) {
          self.language = self.languages[0];
          self.$router.replace('/website/cms/' + self.$route.params.template + '/' + self.languages[0].id + '/0');
        }
      });

      if (self.$route.params.language > 0) {
        Http.request('GET', '/website/forms/' + self.$route.params.language).then((forms) => {
          self.forms = forms.data;
        });
      }
    }
  }
}
</script>

<style scoped>
  .btn-language {
    padding: 0.750rem;
    min-width: 100px;
    min-height: 90px;
  }
  .btn-language img {
    max-height: 38px;
    max-width: 90px;
    margin-bottom: 4px;
  }
</style>
